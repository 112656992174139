var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { staticClass: "system_state", attrs: { gutter: 12 } },
                [
                  _vm.state.os
                    ? _c("el-card", { staticClass: "card_item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "bold",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [_vm._v("Runtime")]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 10 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "bold",
                                        attrs: { span: 10 }
                                      },
                                      [_vm._v("os:")]
                                    ),
                                    _c("el-col", {
                                      attrs: { span: 10 },
                                      domProps: {
                                        textContent: _vm._s(_vm.state.os.goos)
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 10 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "bold",
                                        attrs: { span: 10 }
                                      },
                                      [_vm._v("cpu nums:")]
                                    ),
                                    _c("el-col", {
                                      attrs: { span: 10 },
                                      domProps: {
                                        textContent: _vm._s(_vm.state.os.numCpu)
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 10 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "bold",
                                        attrs: { span: 10 }
                                      },
                                      [_vm._v("compiler:")]
                                    ),
                                    _c("el-col", {
                                      attrs: { span: 10 },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.state.os.compiler
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 11 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "bold",
                                        attrs: { span: 11 }
                                      },
                                      [_vm._v("go version:")]
                                    ),
                                    _c("el-col", {
                                      attrs: { span: 11 },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.state.os.goVersion
                                        )
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 11 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "bold",
                                        attrs: { span: 11 }
                                      },
                                      [_vm._v("goroutine nums:")]
                                    ),
                                    _c("el-col", {
                                      attrs: { span: 11 },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.state.os.numGoroutine
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "system_state", attrs: { gutter: 11 } },
                [
                  _vm.state.disk
                    ? _c("el-card", { staticClass: "card_item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "bold",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [_vm._v("Disk")]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 11 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 11 } },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 11 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 11 }
                                          },
                                          [_vm._v("total (MB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 11 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.state.disk.totalMb
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 11 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 11 }
                                          },
                                          [_vm._v("used (MB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 11 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.state.disk.usedMb
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 11 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 11 }
                                          },
                                          [_vm._v("total (GB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 11 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.state.disk.totalGb
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 11 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 11 }
                                          },
                                          [_vm._v("used (GB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 11 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.state.disk.usedGb
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 11 } },
                                  [
                                    _c("el-progress", {
                                      attrs: {
                                        width: 100,
                                        type: "dashboard",
                                        percentage: _vm.state.disk.usedPercent,
                                        color: _vm.colors
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "system_state", attrs: { gutter: 11 } },
                [
                  _vm.state.cpu
                    ? _c(
                        "el-card",
                        {
                          staticClass: "card_item",
                          attrs: {
                            "body-style": {
                              height: "180px",
                              "overflow-y": "scroll"
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bold",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [_vm._v("CPU")]
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "bold",
                                      attrs: { span: 12 }
                                    },
                                    [_vm._v("physical number of cores:")]
                                  ),
                                  _c("el-col", {
                                    attrs: { span: 12 },
                                    domProps: {
                                      textContent: _vm._s(_vm.state.cpu.cores)
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._l(_vm.state.cpu.cpus, function(item, index) {
                                return [
                                  _c(
                                    "el-row",
                                    { key: index, attrs: { gutter: 10 } },
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v("core " + _vm._s(index) + ":")
                                      ]),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c("el-progress", {
                                            attrs: {
                                              type: "line",
                                              percentage: +item.toFixed(0),
                                              color: _vm.colors
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { staticClass: "system_state", attrs: { gutter: 12 } },
                [
                  _vm.state.ram
                    ? _c("el-card", { staticClass: "card_item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "bold",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [_vm._v("Ram")]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 12 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 12 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("total (MB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 12 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.state.ram.totalMb
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 12 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("used (MB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 12 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.state.ram.usedMb
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 12 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("total (GB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 12 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.state.ram.totalMb / 1024
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 12 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bold",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("used (GB)")]
                                        ),
                                        _c("el-col", {
                                          attrs: { span: 12 },
                                          domProps: {
                                            textContent: _vm._s(
                                              (
                                                _vm.state.ram.usedMb / 1024
                                              ).toFixed(2)
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c("el-progress", {
                                      attrs: {
                                        width: 100,
                                        type: "dashboard",
                                        percentage: _vm.state.ram.usedPercent,
                                        color: _vm.colors
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "system_state", attrs: { gutter: 12 } },
                [
                  _vm.state.cpu
                    ? _c(
                        "el-card",
                        {
                          staticClass: "card_item",
                          staticStyle: { height: "auto" },
                          attrs: {
                            "body-style": {
                              height: "230px",
                              "overflow-y": "scroll"
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_vm._v("Api请求")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("LineChart", {
                                style: { width: "100%", height: "200px" },
                                attrs: { options: _vm.options }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }